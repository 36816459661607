<template>
    <div class="lct-detail-content">
        <div class="lct-detail-head">
            <div class="lct-detail-head-main">
                <div class="lct-detail-head-crumbs">
                    <span class="on">直播</span><i class="iconfont icon-jiantou"></i>
                    <router-link to="/seminars">讲座会议</router-link>
                    <i class="iconfont icon-jiantou"></i><span>{{ info.title }}</span>
                </div>
                <div class="lct-detail-head-video" v-if="!videoFalse">
                    <video id="player-container-id" style="width:100%; height:100%;" preload="auto" playsinline
                           webkit-playsinline></video>
                </div>
                <div class="lct-detail-head-video" v-else>
                    <div class="no-video-err">
                        暂无视频源或者视频正在剪辑中...
                    </div>
                </div>
            </div>
        </div>
        <div class="play-content">
            <el-tabs v-model="activeName">
                <el-tab-pane label="选择章节" name="first"></el-tab-pane>
                <el-tab-pane label="学员留言" name="second"></el-tab-pane>
                <el-tab-pane label="课程介绍" name="info"></el-tab-pane>
            </el-tabs>
            <template v-if="activeName == 'first'">
                <div class="play-chapter-item" v-for="(item,index) in list" :key="index">
                    <div class="play-chapter-item-title">
                        {{ item.chapter_name }}
                    </div>
                    <div class="play-chapter-item-bar" v-for="(arr,i) in item.video_list" :key="i"
                         :class="videoData.id == arr.id?'on':''" @click="videoChange(arr)">
                        <div class="play-chapter-item-bar-name"><img
                                :src="videoData.id == arr.id? 'https://image.ysxxlm.cn/ysxxlm/pc/static/img/play/play-on2@2x.png':'https://image.ysxxlm.cn/ysxxlm/pc/static/img/play/play@2x.png'"/>{{
                            arr.title }}
                        </div>
                        <div class="play-chapter-item-bar-no" v-if="videoData.id != arr.id">
                            <span>{{ arr.lenght }}</span><strong>|</strong><span>{{ arr.pv }}次观看</span></div>
                        <div class="play-chapter-item-bar-start" v-if="videoData.id == arr.id"><img
                                src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/play/zhibo.gif"/> 正在播放
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="activeName == 'second'">
                <div class="play-chat">
                    <textarea placeholder="我也来说..." v-model="sendTxt"></textarea>
                    <div class="play-chat-send">
                        <el-button type="primary" round :disabled="this.sendTxt == ''" @click="send()">发送</el-button>
                    </div>
                </div>
                <div class="play-chat-list">
                    <div class="play-chat-list-item" v-for="(item,index) in chatList">
                        <div class="play-chat-item-head"><img :src="item.face || require('../../assets/img/my.png')"/>
                        </div>
                        <div class="play-chat-item-other">
                            <div class="play-chat-item-title">{{ item.real_name }}</div>
                            <div class="play-chat-item-content">
                                {{ item.content }}
                            </div>
                            <div class="play-chat-item-time">{{ item.createtime }}</div>
                        </div>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination
                            background
                            layout="prev, pager, next"
                            :page-count="total"
                            @current-change="currentChange">
                    </el-pagination>
                </div>
            </template>
            <template v-if="activeName == 'info'">
                <div v-html="details.details"></div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                list: [], // 章节列表
                info: {}, // 详情信息
                details: {}, // 详情信息
                videoData: {}, // 当前播放信息
                tcplayer: null,
                activeName: 'first',
                total: 0,
                page: 1,

                sendTxt: '',
                chatList: [], // 聊天列表
                websock: null,
                videoFalse: false
            }
        },
        mounted() {
            this.getPlay();
            this.getDetail();
        },
        destroyed() {
            if (this.tcplayer) {
                this.tcplayer.dispose()
            }
        },
        computed: {
            uid() {
                return this.$store.state.uid
            }
        },
        methods: {
            getDetail() {
                this.$axios.post(this.apiUrl.courseDetails, {
                    id: this.$route.params.id
                }).then(res => {
                    this.details = res.data;
                })
            },
            getPlay() {
                this.$axios.post(this.apiUrl.CourseCatalog, {
                    id: this.$route.params.id,
                    tab: 2
                }).then(res2 => {
                    if (res2.code == 1) {
                        this.$axios.post(this.apiUrl.course, {
                            id: this.$route.params.id
                        }).then(res => {
                            if (res.code == 1) {
                                this.info = res.data;
                                this.list = res2.data.video;
                                if (this.list.length > 0 && this.list[0].video_list.length > 0) {
                                    this.videoData = res2.data.video[0].video_list[0];
                                    this.play(this.videoData.file_id);
                                } else {
                                    this.videoFalse = true
                                }
                                this.getChat();
                                this.initWebSocket()
                                this.study_add({
                                    uid: this.uid,
                                    vid: this.videoData.id,
                                    study_time: this.videoData.study_time,
                                    leng_time: 1,
                                    duration: this.videoData.second,
                                    status: 1,
                                })
                            } else {
                                this.$message.warning(res.msg)
                                this.$router.replace('/')
                            }

                        })
                    } else {
                        this.$message.warning(res2.msg);
                        this.$router.replace({path: `/lct/${this.$route.params.id}/detail`})
                    }

                })
            },
            getChat() {
                this.$axios.post(this.apiUrl.chat2, {
                    cid: this.$route.params.id,
                    cstyle: 2,
                    page: this.page,
                    rank: 2
                }).then(res => {
                    this.total = res.data.allpage;
                    this.chatList = res.data.list;
                })
            },
            currentChange(e) {
                this.page = e;
                this.getChat();
            },
            play(file_id) {
                this.$axios.post(this.apiUrl.getVideoUrl_One, {
                    fileid: file_id
                }).then(res => {
                    this.videoData.file_id = file_id;
                    this.videoData.psign = res.data.signpc;
                    this.tcplayer = TCPlayer('player-container-id', {
                        fileID: this.videoData.file_id,
                        appID: '1258924221',
                        psign: this.videoData.psign,
                        poster: this.info.banner,
                        autoplay: true,
                        //其他参数请在开发文档中查看
                        plugins: {
                            ProgressMarker: true,
                            ContinuePlay: {
                                auto: true,
                                text: '上次播放至 '
                            }
                        }
                    });
                })
            },
            videoChange(obj) {
                this.videoData = obj;
                this.$axios.post(this.apiUrl.getVideoUrl_One, {
                    fileid: this.videoData.file_id
                }).then(res => {
                    this.videoData.psign = res.data.signpc;
                    this.tcplayer.loadVideoByID({
                        fileID: this.videoData.file_id,
                        appID: '1258924221',
                        psign: this.videoData.psign,
                        autoplay: true
                    });
                    this.$message.success('切换成功');
                    this.study_add({
                        uid: this.uid,
                        vid: this.videoData.id,
                        study_time: this.videoData.study_time,
                        leng_time: 1,
                        duration: this.videoData.second,
                        status: 1,
                    })
                })
            },

            send() {
                // console.log(this.sendTxt)
                if (this.sendTxt == '' || !this.sendTxt) {
                    this.$message.warning("请输入内容");
                    return false
                }
                this.$axios.post(this.apiUrl.setChat, {
                    cid: this.$route.params.id,
                    content: this.sendTxt,
                    content_type: "text",
                    cstyle: 2
                }).then(res => {
                    if (res.code == 1) {
                        res.data.createtime = this.formatDate(res.data.createtime)
                        this.websocketsend(JSON.stringify({
                            type: 2,
                            nickname: res.data.real_name,
                            ...res.data
                        }));
                        console.log({
                            type: 2,
                            nickname: res.data.real_name,
                            ...res.data
                        })
                        this.sendTxt = ''
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            formatDate(date) {
                var date = new Date(date * 1000);
                var YY = date.getFullYear() + '-';
                var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
                var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
                return YY + MM + DD + " " + hh + mm + ss;
            },
            study_add(obj) {
                this.$axios.post(this.apiUrl.study_add, obj).then(res => {
                })
            },
            initWebSocket() { //初始化weosocket
                const wsuri = "wss://yalaworker.ysxxlm.com";
                this.websock = new WebSocket(wsuri);
                this.websock.onmessage = this.websocketonmessage;
                this.websock.onopen = this.websocketonopen;
                this.websock.onerror = this.websocketonerror;
                this.websock.onclose = this.websocketclose;
            },
            websocketonopen() { //连接建立之后执行send方法发送数据
                let param = {
                    "type": 7,
                    "cid": this.$route.params.id,
                    "class": "Live"
                };
                this.websocketsend(JSON.stringify(param));
            },
            websocketonerror() { //连接建立失败重连
                this.initWebSocket();
            },
            websocketonmessage(e) { //数据接收
                const redata = JSON.parse(e.data);
                //这里分析回复的内容，执行不同的操作
                //写自己的业务逻辑
                var myDate = new Date();
                var tiem = myDate.getFullYear() + '-' + (myDate.getMonth() + 1) + '-' + myDate.getDate() + ' ' + myDate.getHours() +
                    ':' + (myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes() + ':' + myDate.getSeconds())
                if (redata && redata.message == "interactiveChatMsg") {
                    //直播间弹幕消息
                    var message_data = JSON.parse(redata.data);
                    // console.log(message_data)
                    this.chatList.unshift({
                        "face": message_data.face,
                        "real_name": message_data.nickname,
                        "content": message_data.content,
                        uid: message_data.uid,
                        create_time: tiem,
                        createtime: tiem,
                        comment_type: 1,
                    });
                }
                if (redata && redata.message == "send_gift") {
                    //直播礼物消息
                }
                /* 抽奖按钮显示 */
                if (redata && redata.message == "startSeckill") {
                }
                /* 中奖人数显示 */
                if (redata && redata.message == "seckill_success") {
                }
                if (redata && redata.message == "startLive") {
                }
                if (redata && redata.message == "endLive") {
                }
            },
            websocketsend(Data) { //数据发送
                this.websock.send(Data);
            },
            websocketclose(e) { //关闭
                console.log('断开连接', e);
            }
        }
    }
</script>

<style scoped>

</style>
